import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
  ) { }

  signIn(data: any){
    return this.http.post(environment.apiUrl + 'users/login', data);
  }

  signOut(){
    return this.http.post(environment.apiUrl + 'users/logout', {});
  }

  signUp(data: any){
    return this.http.post(environment.apiUrl + 'users/register', data)
  }

  me(){
    return this.http.get(environment.apiUrl + 'users/me');
  }

  changePassword(data: any){
    return this.http.patch(environment.apiUrl+'users/change-password', data)
  }

  updateProfile(data: any){
    return this.http.patch(environment.apiUrl + 'users/profile', data)
  }

  forgotPasswordStart(data: any){
    return this.http.post(environment.apiUrl + 'users/forgotpassword/start', data);
  }

  forgotPasswordFinish(data: any){
    return this.http.patch(environment.apiUrl + 'users/forgotpassword/finish', data);
  }

  verifyEmail(data: any){
    return this.http.post(environment.apiUrl + 'users/verify-email', data);
  }

  versionVisited(){
    return this.http.patch(environment.apiUrl + '/users/version-visited', {});
  }
  changeHelpTipsVisibility(data:any){
    return this.http.patch(environment.apiUrl + '/users/helptips/enable-disable', data);
  }
  checkUsernameAvailability(data: any){
    return this.http.post(environment.apiUrl + '/users/check-username-availability', data);
  }
  refreshToken(){
    return this.http.get(environment.apiUrl + 'users/refresh-token');
  }
  getSubscription(){
    return this.http.get(environment.apiUrl + 'subscriptions/uses');
  }


  private subscriptionCount = new BehaviorSubject<any>(null);
  subscriptionCount$ = this.subscriptionCount.asObservable();

  setSubscriptionCount(data: any) {
    // console.log("subscriptions count", data);
    this.subscriptionCount.next(data);
  }

  private refreshTrigger = new Subject<void>();
  refreshTrigger$ = this.refreshTrigger.asObservable();

  // triggerRefresh() {
  //   this.refreshTrigger.next();
  // }

  refreshSubscriptionCount() {
    this.getSubscription().subscribe(
      (result: any) => {
        this.setSubscriptionCount(result);
      },
      (error: any) => {
        console.log('error', error.error);
      }
    );
  }
}
